import styled from '@emotion/styled';
import imagePlaecholder2XL from './assets/img/placeholder/image/2XL.png';
import imagePlaecholderXS from './assets/img/placeholder/image/2XS.png';
import imagePlaecholderL from './assets/img/placeholder/image/L.png';
import imagePlaecholderM from './assets/img/placeholder/image/M.png';
import imagePlaecholderS from './assets/img/placeholder/image/S.png';
import imagePlaecholderXL from './assets/img/placeholder/image/XL.png';
import imagePlaecholder2XS from './assets/img/placeholder/image/XS.png';

import companyPlaecholder2XL from './assets/img/placeholder/company/2XL.png';
import companyPlaecholderXS from './assets/img/placeholder/company/2XS.png';
import companyPlaecholderL from './assets/img/placeholder/company/L.png';
import companyPlaecholderM from './assets/img/placeholder/company/M.png';
import companyPlaecholderS from './assets/img/placeholder/company/S.png';
import companyPlaecholderXL from './assets/img/placeholder/company/XL.png';
import companyPlaecholder2XS from './assets/img/placeholder/company/XS.png';

export const Field_Keys = {
  BRAND: {
    ID: 'brand_id',
    GROUP_ID: 'brand_group_id',
    GROUP_NAME: 'brand_group_name',
    NAME: 'brand_name',
    ALIAS: 'brand_alias',
    IMAGE: 'brand_image',
    INDUSTRY: 'brand_industry',
    IS_CONTRACTED: 'brand_is_contracted',
    HAS_SORTERS: 'brand_has_sorters',
    SORTERS: 'brand_sorters',
    HAS_SSO_IDP: 'brand_has_sso_idp',
    PLAN_NAME: 'brand_plan_name',
    PLAN_END_DATE: 'brand_plan_end_date',
    PLAN_COUNT: 'brand_plan_count',
    CLIENT_COUNT: 'brand_client_count',
    CONTRACT_COUNT: 'brand_contract_count',
    INVOICE_COUNT: 'brand_invoice_count',
    SERVICE_COUNT: 'brand_service_count',
    REP_USER_ID: 'brand_rep_user_id',
    REP_NAME: 'brand_rep_name',
    REP_EMAIL: 'brand_rep_email',
    REP_IMAGE: 'brand_rep_image',
    STATUS: 'brand_status',
    CREATED_AT: 'brand_created_at',
    UPDATED_AT: 'brand_updated_at',
  },
  PRODUCT: {
    ID: 'product_id',
    ALIAS: 'product_alias',
    PHOTOS: 'product_photos',
    STATUS: 'product_status',
    STATUS_NEXT_OPTIONS: 'product_status_next_options',
    STATUS_UPDATED_AT: 'product_status_updated_at',
    STATUS_HISTORY: 'product_status_history',
    IS_PRODUCT_OWNER: 'product_is_product_owner',
    IS_SORTER_PRODUCT: 'product_is_sorter_product',
    BRAND_ID: 'product_brand_id',
    BRAND_NAME: 'product_brand_name',
    BRAND_GROUP_NAME: 'product_brand_group_name',
    BRAND_ALIAS: 'product_brand_alias',
    BRAND_IMAGE: 'product_brand_image',
    IS_BRAND_CONTRACTED: 'product_is_brand_contracted',
    ATTACHMENTS: 'product_attachments',
    NOTES: 'product_notes',
    TICKETS: 'product_tickets',
    ACTIVITY: 'product_activity',
    ITEM_QUANTITY: 'product_item_quantity',
    CATEGORY_ID: 'product_category_id',
    CATEGORY_NAME: 'product_category_name',
    SUBCATEGORY_ID: 'product_subcategory_id',
    SUBCATEGORY_NAME: 'product_subcategory_name',
    REMAINING_AUTHENTICATION_TIME: 'product_remaining_authentication_time',
    HAS_SUPPORT_TICKETS: 'product_has_support_tickets',
    LOG_TYPE: 'product_log_type',
    LOG_CREATED_AT: 'product_log_created_at',
    LOG_CONTENT: 'product_log_content',
    LOG_CREATING_USER_BRAND_ID: 'product_log_creating_user_brand_id',
    LOG_CREATING_USER_IMAGE: 'product_log_creating_user_image',
    LOG_CREATING_USER_DISPLAY_NAME:
      'product_log_creating_user_brand_display_name',
    LOG_CREATING_USER_BRAND_IMAGE: 'product_log_creating_user_brand_image',
    NOTE: 'product_note',
    NOTE_CREATING_USER_DISPLAY_NAME: 'product_note_creating_user_display_name',
    NOTE_CREATING_USER_IMAGE: 'product_note_creating_user_image',
    NOTE_CREATED_AT: 'product_note_created_at',
    CREATED_AT: 'product_created_at',
    UPDATED_AT: 'product_updated_at',
  },
  PARCEL: {
    ID: 'parcel_id',
    ALIAS: 'parcel_alias',
    PHOTOS: 'parcel_photos',
    STATUS: 'parcel_status',
    SIDELOAD_DATE: 'parcel_sideload_date',
    RISK_TYPE: 'parcel_risk_type',
    RISK_TYPE_ORIGINAL: 'parcel_risk_type_original',
    ACTORS: 'parcel_actors',
    ACTIVITY: 'parcel_activity',
    AUTHORITY: 'parcel_authority',
    DETECTION: 'parcel_detection',
    BARCODES: 'parcel_barcodes',
    STATUS_UPDATED_AT: 'parcel_status_updated_at',
    TRACKING_NUMBER: 'parcel_tracking_number',
    PRODUCTS: 'parcel_products',
    PRODUCT_COUNT: 'parcel_product_count',
    PRODUCT_IDS: 'parcel_product_ids',
    SITUATION: 'parcel_situation',
    FILES: 'parcel_files',
    TOTAL_ITEM_QUANTITY: 'parcel_total_item_quantity',
    OWN_ITEM_QUANTITY: 'parcel_own_item_quantity',
    PRODUCT_STATUS_DISTRIBUTION: 'parcel_product_status_distribution',
    SHOULD_SHOW_ACTORS: 'parcel_should_show_actors',
    LAST_PRODUCT_ACTIVITY: 'parcel_last_product_activity',
    DETECTION_CONFIDENCE: 'parcel_detection_confidence',
    DETECTION_STORAGE_NAME: 'parcel_detection_storage_name',
    DETECTION_REFERENCE_FULL: 'parcel_detection_reference_full',
    DETECTION_MATCH_TYPE: 'parcel_detection_match_type',
    DETECTION_MATCH_TEXT: 'parcel_detection_match_text',
    DETECTION_OCR_OUTPUT: 'parcel_detection_ocr_output',
    DETECTION_TYPE: 'parcel_detection_type',
    DETECTION_IMAGE_NAME: 'parcel_detection_image_name',
    BARCODE: 'parcel_barcode',
    REVIEW: 'parcel_review',
    VOLUME: 'parcel_volume',
    WEIGHT: 'parcel_weight',
    WEIGHT_RAW: 'parcel_weight_raw',
    LENGTH: 'parcel_length',
    WIDTH: 'parcel_width',
    HEIGHT: 'parcel_height',
    NO_OF_PREVIOUS_SORTATIONS: 'parcel_no_of_previous_sortations',
    IS_WATCHED: 'parcel_is_watched',
    SITUATION_ID: 'parcel_situation_id',
    SITUATION_CODE: 'parcel_situation_code',
    SITUATION_TITLE: 'parcel_situation_title',
    SITUATION_INSTRUCTIONS: 'parcel_situation_instructions',
    SITUATION_STYLE: 'parcel_situation_style',
    SITUATION_BUTTONS: 'parcel_situation_buttons',
    SITUATION_CONFIRM_BUTTONS: 'parcel_situation_confirm_buttons',
    SITUATION_ALERTS: 'parcel_situation_alerts',
    IS_CHECKED_IN: 'parcel_is_checked_in',
    IS_HOLD_CONFIRMED: 'parcel_is_hold_confirmed',
    IS_STOP_CONFIRMED: 'parcel_is_stop_confirmed',
    IS_SEIZE_CONFIRMED: 'parcel_is_seize_confirmed',
    IS_REPORT_CONFIRMED: 'parcel_is_report_confirmed',
    HAS_SENDER_INPUT: 'parcel_has_sender_input',
    HAS_RECEIVER_INPUT: 'parcel_has_receiver_input',
    LOG_CREATING_USER_DISPLAY_NAME: 'parcel_log_creating_user_display_name',
    LOG_CONTENT: 'parcel_log_content',
    LOG_TYPE: 'parcel_log_type',
    LOG_CREATED_AT: 'parcel_log_created_at',
    CREATED_AT: 'parcel_created_at',
  },
  SORTER: {
    ID: 'sorter_id',
    NAME: 'sorter_name',
    CITY: 'sorter_city',
    COUNTRY: 'sorter_country',
    BRAND_ID: 'sorter_brand_id',
    BRAND_NAME: 'sorter_brand_name',
    BRAND_ALIAS: 'sorter_brand_alias',
    BRAND_IMAGE: 'sorter_brand_image',
    IS_BRAND_CONTRACTED: 'sorter_is_brand_contracted',
  },
  REFERENCE: {
    ID: 'reference_id',
    ALIAS: 'reference_alias',
    NAME: 'reference_name',
    COMPANY: 'reference_company',
    ADDRESS: 'reference_address',
    CITY: 'reference_city',
    STATE: 'reference_state',
    COUNTRY: 'reference_country',
    POSTCODE: 'reference_postcode',
    PHONE: 'reference_phone',
    EMAIL: 'reference_email',
    WEBSITE: 'reference_website',
    NOTES: 'reference_notes',
    BRAND_ID: 'reference_brand_id',
    BRAND_NAME: 'reference_brand_name',
    BRAND_ALIAS: 'reference_brand_alias',
    BRAND_IMAGE: 'reference_brand_image',
    IS_BRAND_CONTRACTED: 'reference_is_brand_contracted',
    TYPE: 'reference_type',
    PRODUCTS: 'reference_products',
    PRODUCT_STATS: 'reference_product_stats',
    LAST_PARCEL_SIDELOAD_DATE: 'reference_last_parcel_sideload_date',
    CREATING_USER_DISPLAY_NAME: 'reference_creating_user_display_name',
    CREATING_USER_IMAGE: 'reference_creating_user_image',
    CREATING_USER_CLIENT_ID: 'reference_creating_user_client_id',
    HAS_SIDELOAD_LIMIT_LEFT: 'reference_has_sideload_limit_left',
    ACTOR_TYPE: 'reference_actor_type',
    CREDIT: 'reference_credit',
    FULL: 'reference_full',
    NAME_WEIGHT: 'reference_name_weight',
    COMPANY_WEIGHT: 'reference_company_weight',
    ADDRESS_WEIGHT: 'reference_address_weight',
    POSTCODE_WEIGHT: 'reference_postcode_weight',
    CITY_WEIGHT: 'reference_city_weight',
    STATE_WEIGHT: 'reference_state_weight',
    COUNTRY_WEIGHT: 'reference_country_weight',
    PHONE_WEIGHT: 'reference_phone_weight',
    SIDELOAD_LIMIT: 'reference_sideload_limit',
    NOTE: 'reference_note',
    NOTE_CREATING_USER_DISPLAY_NAME:
      'reference_note_creating_user_display_name',
    NOTE_CREATING_USER_IMAGE: 'reference_note_creating_user_image',
    NOTE_CREATED_AT: 'reference_note_created_at',
    CREATED_AT: 'reference_created_at',
  },
  CATEGORY: {
    ID: 'category_id',
    NAME: 'category_name',
    SUBCATEGORY_ID: 'category_subcategory_id',
    SUBCATEGORY_NAME: 'category_subcategory_name',
    FULL: 'category_full',
    CREATING_USER_NAME: 'category_creating_user_name',
    CREATING_USER_IMAGE: 'category_creating_user_image',
    CREATED_AT: 'category_created_at',
    SUBCATEGORY_CREATED_AT: 'category_subcategory_created_at',
  },
  NOTIFICATION: {
    ID: 'notification_id',
    TITLE: 'notification_title',
    BRAND_ID: 'notification_brand_id',
    BRAND_NAME: 'notification_brand_name',
    BRAND_ALIAS: 'notification_brand_alias',
    BRAND_IMAGE: 'notification_brand_image',
    IS_BRAND_CONTRACTED: 'notification_is_brand_contracted',
  },
  INSTRUCTION: {
    ID: 'instruction_id',
    BRAND_ID: 'instruction_brand_id',
    BRAND_NAME: 'instruction_brand_name',
    BRAND_ALIAS: 'instruction_brand_alias',
    BRAND_IMAGE: 'instruction_brand_image',
    IS_BRAND_CONTRACTED: 'instruction_is_brand_contracted',
    HAS_BRAND_SORTERS: 'instruction_has_brand_sorters',
    CHARACTERISTICS_COUNT: 'instruction_characteristics_count',
    TITLE: 'instruction_title',
    CONTENT: 'instruction_content',
    ATTACHMENTS: 'instruction_attachments',
    LINKS: 'instruction_links',
    CHARACTERISTICS: 'instruction_characteristics',
    CREATING_USER_IMAGE: 'instruction_creating_user_image',
    CREATING_USER_DISPLAY_NAME: 'instruction_creating_user_display_name',
    CREATING_USER_CLIENT_ID: 'instruction_creating_user_client_id',
    IS_VISIBLE: 'instruction_is_visible',
    CREATED_AT: 'instruction_created_at',
  },
  SUPPORT_TICKET: {
    ID: 'support_ticket_id',
    BRAND_ID: 'support_ticket_brand_id',
    BRAND_NAME: 'support_ticket_brand_name',
    BRAND_ALIAS: 'support_ticket_brand_alias',
    BRAND_IMAGE: 'support_ticket_brand_image',
    IS_BRAND_CONTRACTED: 'support_ticket_is_brand_contracted',
    HAS_BRAND_SORTERS: 'support_ticket_has_brand_sorters',
    CREATING_USER_DISPLAY_NAME: 'support_ticket_creating_user_display_name',
    CREATING_USER_IMAGE: 'support_ticket_creating_user_image',
    REP_USER_DISPLAY_NAME: 'support_ticket_rep_user_display_name',
    REP_USER_IMAGE: 'support_ticket_rep_user_image',
    IS_SUPPORT_TICKET_OWNER: 'support_ticket_is_support_ticket_owner',
    ALIAS: 'support_ticket_alias',
    STATUS: 'support_ticket_status',
    PRIORITY: 'support_ticket_priority',
    TITLE: 'support_ticket_title',
    TYPE: 'support_ticket_type',
    CLIENT_ID: 'support_ticket_client_id',
    REFERENCE_ID: 'support_ticket_reference_id',
    MESSAGE_COUNT: 'support_ticket_message_count',
    LAST_MESSAGE_DATE: 'support_ticket_last_message_date',
    FIRST_MESSAGE: 'support_ticket_first_message',
    IS_READ: 'support_ticket_is_read',
    MESSAGE_CREATING_USER_IMAGE: 'support_ticket_message_creating_user_image',
    MESSAGE_CREATING_USER_DISPLAY_NAME:
      'support_ticket_message_creating_user_display_name',
    MESSAGE_CREATING_CLIENT_TYPE: 'support_ticket_message_creating_client_type',
    MESSAGE_CREATING_USER_IS_ADMIN:
      'support_ticket_message_creating_user_is_admin',
    MESSAGE: 'support_ticket_message',
    MESSAGE_CREATED_AT: 'support_ticket_message_created_at',
    LOG_CONTENT: 'support_ticket_log_content',
    LOG_TYPE: 'support_ticket_log_type',
    LOG_CREATED_AT: 'support_ticket_log_created_at',
    LOG_CREATING_USER_DISPLAY_NAME:
      'support_ticket_log_creating_user_display_name',
    LOG_CREATING_USER_IMAGE: 'support_ticket_log_creating_user_image',
    CAN_EDIT: 'support_ticket_can_edit',
    CREATED_AT: 'support_ticket_created_at',
  },
  PHOTO: {
    ID: 'photo_id',
    THUMBNAIL: 'photo_thumbnail',
    IMAGE: 'photo_image',
  },
  AUTHORITY: {
    ID: 'authority_id',
    NAME: 'authority_name',
    EMAIL: 'authority_email',
    PHONE: 'authority_phone',
    FAX: 'authority_fax',
  },
  USER: {
    ID: 'user_id',
    DISPLAY_NAME: 'user_display_name',
    FIRST_NAME: 'user_first_name',
    LAST_NAME: 'user_last_name',
    EMAIL: 'user_email',
    PHONE: 'user_phone',
    IMAGE: 'user_image',
    PASSWORD: 'user_password',
    REFRESH_TOKEN: 'user_refresh_token',
    TOKEN: 'user_token',
    AUTH_TYPE: 'user_auth_type',
    AUTH_CODE: 'user_auth_code',
    IS_ACTIVE: 'user_is_active',
    SSO_ID: 'user_sso_id',
    CLIENT_ID: 'user_client_id',
    IS_FAVORITE_CLIENT: 'user_is_favorite_client',
    IS_CLIENT_MAIN_BRAND_CONTACT: 'user_is_client_main_brand_contact',
    CLIENT_CREATING_USER_DISPLAY_NAME: 'client_creating_user_display_name',
    CLIENT_CREATING_USER_IMAGE: 'client_creating_user_image',
    CLIENT_ROLES: 'client_roles',
    CREATED_AT: 'user_created_at',
  },
  REPORT: {
    ID: 'report_id',
    TITLE: 'report_title',
    CREATING_USER_DISPLAY_NAME: 'report_creating_user_display_name',
    CREATING_USER_IMAGE: 'report_creating_user_image',
    START_DATE: 'report_start_date',
    END_DATE: 'report_end_date',
    CREATED_AT: 'report_created_at',
  },
  ATTACHMENT: {
    ID: 'attachment_id',
    NAME: 'attachment_name',
  },
};

export const Constants = {
  Logistics: 'Logistics',
  Brand: 'Brand',
  Status: {
    Sideload: 'Sideload',
    Inspection: 'Inspection',
    // getFilters.js filterStatusOptions uses this object to create status badges. DRY the function
    Authentication: 'Authentication',
    Counterfeit: 'Counterfeit',
    'Non-counterfeit': 'Non-counterfeit',
    AuthenticationCompleted: 'Authentication_Completed',
    Stopped: 'Stopped',
    Customs: 'Customs',
    Seized: 'Seized',
    Released: 'Released',
    Returned: 'Returned',
    // Office statues
    OfficeStop: 'Counterfeit',
    OfficeRelease: 'Non-counterfeit',
  },
  StatusLabel: {
    Sideload: 'Sideload',
    Inspection: 'Inspection',
    // getFilters.js filterStatusOptions uses this object to create status badges. DRY the function
    // Inspection: 'Inspection',
    Authentication: 'Authentication',
    // authentication completed
    Counterfeit: 'Counterfeit',
    'Non-counterfeit': 'Non-counterfeit',
    AuthenticationCompleted: 'Completed',
    Stopped: 'Stopped',
    Customs: 'Customs',
    Seized: 'Seized',
    Released: 'Released',
    Returned: 'Returned',
    // Office statues
    OfficeStop: 'Office Stop',
    OfficeRelease: 'Office Release',
  },
  StatusPastTense: {},
  BrandStatus: {
    Active: 'Active',
    Processing: 'Processing',
    Inactive: 'Inactive',
  },
  FileUpload: {
    extensionError: 'extensionError',
    fileSizeError: 'fileSizeError',
    uploadError: 'uploadError',
    permissionError: 'permissionError',
    unexpectedError: 'There was an unexpected error. Please, try again',
    uploadType: {
      image: 'image',
      spreadsheet: 'spreadsheet',
      knowledge_base: 'knowledge_base',
      attachments: 'attachments',
      brand_contract: 'brand_contract',
      brand_invoice: 'brand_invoice',
      brand_details: 'brand_details',
    },
    uploadTypeUtils: {
      image: {
        validMimeTypes: ['image/jpeg', 'image/png'],
        extensionError: 'Please upload a JPG or PNG file',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      spreadsheet: {
        validMimeTypes: [
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv',
        ],
        extensionError: 'Please upload a XLS or CSV file',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      // Word, PDF, Excel, Image
      knowledge_base: {
        validMimeTypes: [
          'image/jpeg',
          'image/png',
          // 'application/vnd.ms-powerpoint',
          // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv',
        ],
        extensionError:
          'Please upload any of the following file types:\nJPG, PNG,  Word, Excel, CSV, or PDF',
        // 'Please upload any of the following file types:\nJPG, PNG, Powerpoint, Word, Excel, CSV, or PDF',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      brand_details: {
        //  Image
        validMimeTypes: ['image/jpeg', 'image/png'],
        extensionError:
          'Please upload any of the following file types:\nJPG, PNG,  Word, or PDF',
        // 'Please upload any of the following file types:\nJPG, PNG, Powerpoint, Word, Excel, CSV, or PDF',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      brand_contract: {
        // Word, PDF, Image
        validMimeTypes: [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ],
        extensionError:
          'Please upload any of the following file types:\nJPG, PNG,  Word, or PDF',
        // 'Please upload any of the following file types:\nJPG, PNG, Powerpoint, Word, Excel, CSV, or PDF',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      brand_invoice: {
        // Word, PDF, Image
        validMimeTypes: [
          'application/pdf',
          'image/jpeg',
          'image/png',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
        ],
        extensionError:
          'Please upload any of the following file types:\nJPG, PNG,  Word, or PDF',
        // 'Please upload any of the following file types:\nJPG, PNG, Powerpoint, Word, Excel, CSV, or PDF',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
      // Word, PDF, Excel, Image
      attachments: {
        validMimeTypes: [
          'image/jpeg',
          'image/png',
          // 'application/vnd.ms-powerpoint',
          // 'application/vnd.openxmlformats-officedocument.presentationml.presentation',
          'application/msword',
          'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
          'application/pdf',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
          'text/csv',
        ],
        extensionError:
          'Please upload any of the following file types:\nJPG, PNG,  Word, Excel, CSV, or PDF',
        // 'Please upload any of the following file types:\nJPG, PNG, Powerpoint, Word, Excel, CSV, or PDF',
        fileSizeError: 'Maximum file size is 50MB',
        fileSize: 52428800,
      },
    },
  },
  fallback: ' - ',
  empty: '',
  emptyKeyword: '!empty!',
  all: 'all',
  lsState: 'lsState',
  Snackbar: {
    error: 'Error',
    warning: 'Warning',
    success: 'Success',
  },
  OwnBrandKeyword: 'Your',
  DropdownStatus: {},
  noLimit: 1e9,
  excludeFilters: '!EXCLUDE!',
  unknownBrandIds: ['unk-id', 'ea6d68da-68b6-4566-8a4a-cdde9315bac5'],
  isSelf: 'self',
  Mock: {
    image: 'https://source.unsplash.com/random',
  },
};

export const Prose = {
  allBrandsDisabled: 'Switch to a specific brand in order to use this feature',
  noPermission: "You don't have permission to perform this action",
};

Constants.DropdownStatus = {
  [Constants.Status.Sideload]: Constants.Status.Sideload,
  [Constants.Status.Inspection]: Constants.Status.Inspection,
  [Constants.Status.Authentication]: Constants.Status.Authentication,
  [Constants.Status.Counterfeit]: Constants.Status.Counterfeit,
  [Constants.Status['Non-counterfeit']]: Constants.Status['Non-counterfeit'],
  [Constants.Status.Stopped]: Constants.Status.Stopped,
  [Constants.Status.Seized]: Constants.Status.Seized,
  [Constants.Status.Released]: Constants.Status.Released,
};

Constants.StatusPastTense = {
  [Constants.Status.Sideload]: 'Sideloaded',
  [Constants.Status.Inspection]: 'Sent for Inspection',
  [Constants.Status.Authentication]: 'Sent for Authentication',
  [Constants.Status.Stopped]: 'Stopped',
  [Constants.Status.Seized]: 'Seized',
  [Constants.Status.Released]: 'Released',
};

export const scrollbarOffset = '8px';

export const ResponsiveSizes = {
  minPageWidth: 1048,
  maxContentWidth: 1400,
  minWindowHeight: 700,
};

export const Styles = {
  flex: {
    display: 'flex',
    alignItems: 'center',
  },
  flexCenter: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexBetween: {
    display: 'flex',
    justifyContent: 'space-between',
  },
  flexBetweenCenter: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  flexAroundCenter: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
  },
  flexColumn: {
    display: 'flex',
    flexDirection: 'column',
  },
  flexColumnCenter: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  flexEnd: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
  },
  flexColumnStart: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'start',
  },
  flexColumnEnd: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'end',
  },
  absoluteOveray: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  // Layout.Spacing._S
  gridColumnGap: '16px',
  scroll: {
    height: 0,
    flexGrow: 1,
    overflow: 'auto',
    overflowX: 'hidden',
    width: `calc(100% + ${scrollbarOffset})`,
  },
};

export const Utils = {
  emptyFunction: () => {},
  falseReturn: () => false,
  trueReturn: () => true,
  emptyObjectFunction: () => {
    return {};
  },
  emptyFragment: () => <></>,
  unary: x => x,
  stopPropagation: e => e?.stopPropagation(),
  expandRow: () => ({ expand: true }),
  avoidRowExpand: () => ({ expand: false }),
  avoidRowAction: () => false,
};

export const PropKeys = {
  addReferenceToggle: 'addReferenceToggle',
  authCode: 'authCode',
  authType: 'authType',
  brandId: 'brandId',
  brandImage: 'brandImage',
  brandName: 'brandName',
  brandStatus: 'brandStatus',
  favoritedBrand: 'favoritedBrand',
  clients: 'clients',
  clientId: 'clientId',
  email: 'email',
  firstName: 'firstName',
  lastName: 'lastName',
  isCurrentBrand: 'isCurrentBrand',
  isLogisticsUser: 'isLogisticsUser',
  phone: 'phone',
  userImage: 'userImage',
  username: 'username',
  sorters: 'sorters',
  storageToken: 'storageToken',
  unreadSupportMessageCount: 'unreadSupportMessageCount',
  userId: 'userId',
  sorterId: 'sorter_id',
};

// TODO: update this to better key mask
export const PropKeysMask = {
  storage_token: PropKeys.storageToken,
  [Field_Keys.BRAND.ID]: PropKeys.brandId,
  [Field_Keys.BRAND.NAME]: PropKeys.brandName,
  [Field_Keys.BRAND.IMAGE]: PropKeys.brandImage,
  [Field_Keys.USER.IS_FAVORITE_CLIENT]: PropKeys.favoritedBrand,
  [Field_Keys.BRAND.STATUS]: PropKeys.brandStatus,
  [Field_Keys.BRAND.SORTERS]: PropKeys.sorters,
};

// DON'T include any dropdowns here
export const DISPATCH_CONSTANTS = {
  ALL: 'ALL',
  ERROR: 'ERROR',
  FILTERS_SET: 'FILTERS_SET',
  HAS_RESET: 'HAS_RESET',
  ITEMS_PER_PAGE: 'ITEMS_PER_PAGE',
  OTHERS: 'OTHERS',
  OWN: 'OWN',
  PAGE: 'PAGE',
  REFRESH: 'REFRESH',
  RESET: 'RESET',
  SEARCH: 'SEARCH',
  SEARCH_TRIGGER: 'SEARCH_TRIGGER',
  SET: 'SET',
  TIME_FRAME: 'TIME_FRAME',
  TIME_FRAME_SET: 'TIME_FRAME_SET',
};

export const envVars = {
  environment: process.env.REACT_APP_ENV,
};

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(
    ${props => props.columns ?? 12},
    minmax(min(10px, 100%), 1fr)
  );
  height: 100%;
  width: 100%;
  align-items: flex-start;
  grid-gap: ${Styles.gridColumnGap};
`;

export const UtilityComponents = {
  Grid,
};

export const Placeholders = {
  image: {
    _2XL: imagePlaecholder2XL,
    _XL: imagePlaecholderXL,
    _L: imagePlaecholderL,
    _M: imagePlaecholderM,
    _S: imagePlaecholderS,
    _XS: imagePlaecholderXS,
    _2XS: imagePlaecholder2XS,
  },
  company: {
    _2XL: companyPlaecholder2XL,
    _XL: companyPlaecholderXS,
    _L: companyPlaecholderL,
    _M: companyPlaecholderM,
    _S: companyPlaecholderS,
    _XS: companyPlaecholderXL,
    _2XS: companyPlaecholder2XS,
  },
};

export const PlaceholdersTypes = {
  image: 'image',
  company: 'company',
};

export const re = {
  uuid: /[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}/g,
  url: /(\S+\.(com|net|org|edu|gov|ms)\b(\/\S+)?)/,
  urlProtocol: /^http[s]?:\/\//,
  ddmmyy: /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/\d{2}$/,
};

// URL re: matches broadly, but we must specify what types of links are valid
// /(\S+\.(com|net|org|edu|gov|ms)\b(\/\S+)?)/
