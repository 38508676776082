import { Constants, DISPATCH_CONSTANTS, Utils } from '../../../constants';
import {
  cloneDeep,
  flow,
  intersection,
  isArray,
  isEmpty,
  isJsonNull,
} from '../../../lib/js';
import { extractSpecialFilters } from '../../../platform/admin/features/parcels/api/getParcels';
import { DC } from '../../../platform/admin/features/parcels/controllers/variables';
import {
  productBrandIsContracted,
  productBrandIsNotContracted,
} from '../../../platform/admin/features/products/controllers/variables';
import {
  emptyOption,
  labelSort,
  selectedSort,
} from '../../../utils/arrayUtils';
import { } from '../../../utils/stringUtils';
import {
  setTimeFrame28Days,
  setTimeFrame3Days,
  setTimeFrame3Months,
  setTimeFrame7Days,
  setTimeFrameThisWeek,
  setTimeFrameToday,
  setTimeFrameUrlParams,
} from '../../../utils/timeUtils';
import { getURLParams } from '../../../utils/utils';
import { Badge, BadgeType } from '../../molecules/Badge/Badge';
import ContractedBrandName from '../../molecules/ContractedBrandName/ContractedBrandName';
import { FilterTypes, resetSearchParamsCommon } from './variables';

export const getFilterParams = ({
  allOptions,
  options,
  ownOptions,
  location,
  filtersConstants,
  isError,
}) => {
  const resetSearchParams = { ...resetSearchParamsCommon };

  if (isError) {
    Object.keys(filtersConstants ?? {}).forEach(key => {
      const { type } = filtersConstants[key];
      if (type === FilterTypes.dropdown) {
        resetSearchParams[key] = [
          {
            section: DISPATCH_CONSTANTS.ERROR,
            options: [{ label: 'Filter currently unavailable', value: '' }],
          },
        ];
      }
    });
  } else {
    allOptions = prepareAllOptions(allOptions);
    ownOptions = prepareOwnOptions(ownOptions);

    removeEmptyOptions(options);

    prepareResetSearchParams({
      resetSearchParams,
      allOptions,
      options,
      ownOptions,
      filtersConstants,
    });
  }

  const initialSearchParams = prepareInitialSearchParams({
    resetSearchParams,
    location,
    filtersConstants,
    options,
  });

  const formInitialValues = prepareFormInitialValues({
    filtersConstants,
    initialSearchParams,
  });

  return {
    initialSearchParams,
    resetSearchParams,
    formInitialValues,
  };
};

const removeEmptyOptions = options => {
  Object.keys(options ?? {}).forEach(type => {
    const filter = options[type];

    options[type] = filter.filter(({ options }) => options.length > 0);
    if (options[type].length === 0) delete options[type];
  });
};

const prepareResetSearchParams = ({
  resetSearchParams,
  allOptions,
  options,
  ownOptions,
  filtersConstants,
}) => {
  Object.keys(options ?? {}).forEach(type => {
    resetSearchParams[type] = defaultsByCategory(
      allOptions?.[type],
      options?.[type],
      ownOptions?.[type]
    );
  });
  const initialParams = Object.keys(filtersConstants ?? {})
    .map(key => ({
      key,
      initial: filtersConstants[key]?.initial,
    }))
    .filter(({ initial }) => initial);

  initialParams.forEach(({ key, initial }) => {
    const paramsSetter = initialCases[initial];
    if (paramsSetter) resetSearchParams[key] = paramsSetter();

    if (initial && paramsSetter)
      resetSearchParams[DISPATCH_CONSTANTS.FILTERS_SET] = true;
    // for some reason, filters with an initial string get set to false
    // so we need to set them to true here
    // otherwise clear() deselects all
    toggleAll(resetSearchParams[key], true);
  });
};

const prepareInitialSearchParams = ({
  resetSearchParams,
  location,
  filtersConstants,
  options,
}) => {
  const initialSearchParams = cloneDeep(resetSearchParams);
  if ((!location || isEmpty(location)) && !filtersConstants)
    return initialSearchParams;

  // get each filter's param key and initial value key
  const urlParams = Object.keys(filtersConstants ?? {})
    .map(key => ({
      key,
      param: filtersConstants[key]?.param,
      initial: filtersConstants[key]?.initial,
    }))
    .filter(({ param, initial }) => param || initial);

  urlParams.forEach(({ key, param, initial }) => {
    const withInitial = cloneDeep(location);
    if (initial) withInitial.search = initial;
    const urlParam = getURLParams(withInitial, param);

    // 1. Use special case from URL param if it exists
    // 2. Select values from dropdown list
    // 3. Use default value
    const paramsSetter =
      (urlParam && urlParamSpecialCases[param]) ||
      (urlParam && initialSearchParams[key]
        ? () => selectFromUrl(initialSearchParams[key], urlParam)
        : () => initialSearchParams[key]);

    initialSearchParams[key] = paramsSetter(
      urlParam,
      location,
      initialSearchParams,
      { resetSearchParams, key }
    );

    if (urlParam) initialSearchParams[DISPATCH_CONSTANTS.FILTERS_SET] = true;
  });

  const savedLightweightSearchParams = location.state?.searchParams;
  if (savedLightweightSearchParams) {
    // table views use SET in ignoreDataFetch to avoid double data fetch
    // so we need to delete it here, or it'll think it's the second time
    delete savedLightweightSearchParams[DISPATCH_CONSTANTS.SET];
    savedLightweightSearchParams[DISPATCH_CONSTANTS.FILTERS_SET] = true;
    // lightweight state will save dropdowns as arrays of selected values
    // so we need to map them back to the dropdown object
    // this needs to happen later so that we don't overwrite the ALL and OWN sections
    mapSavedParamsToInitialSearchParams(
      initialSearchParams,
      savedLightweightSearchParams,
      options
    );
  }

  return initialSearchParams;
};

const mapSavedParamsToInitialSearchParams = (
  initialSearchParams,
  searchParams,
  options
) => {
  Object.keys(searchParams).forEach(key => {
    const savedFilterValues = searchParams[key];
    // if it exists in options, it's a dropdown filter
    if (options?.[key]) {
      // if it's explicitly set to null, it's all. turn everything on
      // undefined means it's not set, so don't do anything
      if (savedFilterValues === null) {
        toggleAll(initialSearchParams[key], true);
      }
      // if it's an array, it's a list of selected values
      if (isArray(savedFilterValues)) {
        toggleAll(initialSearchParams[key], false);
        toggleMatches(initialSearchParams[key], savedFilterValues, true);
      }
    }
    // if it's anything else, just set it
    else initialSearchParams[key] = savedFilterValues;
  });

  return initialSearchParams;
};

export const mapToLightweightState = searchParams => {
  const lightWeightParams = {};

  Object.keys(searchParams).forEach(filter => {
    const filterValue = searchParams[filter];

    if (DISPATCH_CONSTANTS[filter] || !isArray(filterValue)) {
      lightWeightParams[filter] = filterValue;
      return;
    }

    const dropdown = filterValue;
    const lightweightFilterValues = extractSelectedValues(dropdown);
    lightWeightParams[filter] = lightweightFilterValues;
  });

  return lightWeightParams;
};

const prepareFormInitialValues = ({
  filtersConstants,
  initialSearchParams,
}) => {
  const formInitialValues = {};

  Object.keys(filtersConstants ?? {}).forEach(key => {
    formInitialValues[key] = '';
  });

  if (initialSearchParams?.[DISPATCH_CONSTANTS.SEARCH])
    formInitialValues[DISPATCH_CONSTANTS.SEARCH] =
      initialSearchParams[DISPATCH_CONSTANTS.SEARCH];

  return formInitialValues;
};

const maybeUseBulkOption = (
  urlParam,
  location,
  initialSearchParams,
  { resetSearchParams, key }
) => {
  if (
    [productBrandIsContracted, productBrandIsNotContracted].includes(urlParam)
  ) {
    toggleAll(initialSearchParams[key], false);
    toggleSome(initialSearchParams[key], true, urlParam);
  } else
    initialSearchParams[key] = selectFromUrl(resetSearchParams[key], urlParam);

  if (initialSearchParams[key]) findActiveBulkOptions(initialSearchParams[key]);
  return initialSearchParams[key];
};

const excludeValuesFromSection = (
  urlParam,
  location,
  initialSearchParams,
  { resetSearchParams, key }
) => {
  const dropdownSectionBulk = initialSearchParams[key].find(
    ({ section: dropdownSectionTitle }) =>
      DISPATCH_CONSTANTS.ALL === dropdownSectionTitle
  );

  toggleAll([dropdownSectionBulk], false);

  const params = urlParam.split('section').filter(x => x);
  params.forEach(param => {
    const [section, value] = param.split(':').filter(x => x);
    const dropdownSection = initialSearchParams[key].find(
      ({ section: dropdownSectionTitle }) => section === dropdownSectionTitle
    );

    toggleAll([dropdownSection], false);
    toggleSome([dropdownSection], true, value);
  });

  return initialSearchParams[key];
};

const urlParamSpecialCases = {
  search: Utils.unary,
  timeFrame: setTimeFrameUrlParams,
  brand: maybeUseBulkOption,
  filters: excludeValuesFromSection,
};

export const timeFrameInitialFilterValues = {
  today: 'today',
  thisWeek: 'thisWeek',
  last3days: 'last3days',
  last7days: 'last7days',
  last28days: 'last28days',
  _3months: '3months',
};

const t = timeFrameInitialFilterValues;

const initialCases = {
  [t.today]: setTimeFrameToday,
  [t.thisWeek]: setTimeFrameThisWeek,
  [t.last3days]: setTimeFrame3Days,
  [t.last7days]: setTimeFrame7Days,
  [t.last28days]: setTimeFrame28Days,
  [t._3months]: setTimeFrame3Months,
};

const defaultsByCategory = (allSection, sections, ownSection) => {
  const defaults = [];
  if (allSection) defaults.push(allSection);
  if (ownSection) {
    defaults.push(ownSection);
    removeOwnOptionsFromOthers(sections, ownSection);
  }
  if (sections) defaults.push(...sections);
  findActiveBulkOptions(defaults);

  return defaults;
};
const removeOwnOptionsFromOthers = (sections, ownSection) => {
  (sections ?? []).forEach(section => {
    section.options = section.options.filter(
      option => !ownSection.options.some(({ value }) => value === option.value)
    );
  });
};

const prepareAllOptions = allOptions => {
  const prepared = {};

  Object.keys(allOptions ?? {}).forEach(key => {
    let options = allOptions[key];
    if (!options) return;
    options = isArray(options) ? options : [options];

    prepared[key] = {
      section: DISPATCH_CONSTANTS.ALL,
      options: options.map(option => ({
        ...option,
      })),
    };
  });

  return prepared;
};

const prepareOwnOptions = ownOptions => {
  const prepared = {};

  Object.keys(ownOptions ?? {}).forEach(key => {
    let filter = ownOptions[key];
    if (!filter) return;

    const { options, sectionTitle } = filter;

    prepared[key] = {
      section: DISPATCH_CONSTANTS.OWN,
      sectionTitle,
      options: options.map(option => ({
        ...option,
        selected: true,
        selectableBy: [Constants.all, ...(option.selectableBy ?? [])],
      })),
    };
  });

  return prepared;
};

export const mergeFilterStateAndConsts = ({
  filtersState,
  filtersConstants,
}) => {
  const filterKeys = intersection(
    Object.keys(filtersState),
    Object.keys(filtersConstants)
  );

  return filterKeys
    .map(type => {
      return {
        ...filtersConstants[type],
        ...filtersState[type],
      };
    })
    .filter(filter => !isDropdown(filter) || hasDropdownOptions(filter));
};

const isDropdown = filter => filter.type === FilterTypes.dropdown;

const hasDropdownOptions = filter => {
  if (filter.userFilteredOptions) return true;
  const { sections } = filter;

  const withoutCustomSections = sections.filter(
    ({ section }) =>
      section !== DISPATCH_CONSTANTS.OWN && section !== DISPATCH_CONSTANTS.ALL
  );

  return withoutCustomSections.some(({ options }) => options.length > 0);
};

export const prepareDataForDropdown = (
  data,
  label,
  value,
  {
    fallback = '...',
    bulkSelectableTags = [],
    section = DISPATCH_CONSTANTS.OTHERS,
    sectionTitle,
    selected = true,
    customLabel = () => null,
  } = {}
) => {
  let hasEmptyValues = false;
  let formatted = [];

  (data ?? []).forEach(item => {
    if (!item[label] || isJsonNull(item[value])) {
      hasEmptyValues = true;
      return;
    }
    const titleLabel = item[label] || fallback;

    formatted.push({
      label: customLabel(item) || titleLabel,
      value: item[value],
      selectableBy: [
        Constants.all,
        ...getSelectableByValue(bulkSelectableTags, item),
      ],
      selected,
      titleLabel,
    });
  });

  labelSort(formatted);

  if (hasEmptyValues) {
    formatted = [
      { ...emptyOption, selectableBy: [Constants.all], selected },
      ...formatted,
    ];
  }

  const preparedData = { section, options: formatted };
  if (sectionTitle) preparedData.sectionTitle = sectionTitle;

  return [preparedData];
};

export const extractSectionOptions = (options, sectionTitle = DC.OTHERS) =>
  options?.find(section => section?.section === sectionTitle)?.options;

const getSelectableByValue = (options, item) => {
  const matchingOptions = options.filter(({ column, columnValue }) => {
    const matches = isArray(columnValue)
      ? columnValue.includes(item[column])
      : item[column] === columnValue;

    return matches;
  });

  const selectableValues = matchingOptions.map(o => o.selectableByValue);

  return selectableValues;
};

export const extractSelectedValues = sections => {
  if (!sections) return null;
  sections = sections.map(({ options }) => options ?? []).flat(Infinity);

  const allSelected = sections.some(
    s => s?.value === Constants.all && s.selected
  );
  if (allSelected) return null;

  const selectedValues = sections
    .filter(({ selected }) => selected)
    .map(({ value }) => value);

  return selectedValues;
};

export const createStatusBadgeOptions = (statuses, selected = true) => {
  return statuses.map(status => {
    const value = status.value ?? status;
    return {
      value,
      label: <Badge type={BadgeType.status} text={value} size="_S" />,
      status: value,
      titleLabel: value,
      selectableBy: [Constants.all],
      selected,
    };
  });
};

export const withContractedLabel = (
  option,
  { contractedKey, labelKey, size, bold }
) => {
  const { [contractedKey]: isContracted, [labelKey]: label } = option;

  const labelWithIcon = (
    <ContractedBrandName
      brand={label}
      isContracted={!!isContracted}
      size={size}
      bold={bold}
    />
  );

  return labelWithIcon;
};

export const withFullStatus = (params, statusKey) => {
  try {
    const savedStatus = params[statusKey][DISPATCH_CONSTANTS.OTHERS];
    const fullStatus = createStatusBadgeOptions(savedStatus);

    params[statusKey][DISPATCH_CONSTANTS.OTHERS] = fullStatus;
  } catch {
  } finally {
    return params;
  }
};

export const generateDropdown =
  ({
    searchParams: searchParams_,
    resetSearchParams: resetSearchParams_,
    filterValues: filterValues_,
    setFilterValues,
    dispatch,
    titles: titles_,
    isError,
    allSectionsMustHaveSelection,
  }) =>
  type => {
    // limit scope to only one dropdown filter
    const searchParams = cloneDeep(searchParams_[type]);
    const resetSearchParams = cloneDeep(resetSearchParams_[type]);
    const filterValues = cloneDeep(filterValues_[type]);
    const title_ = cloneDeep(titles_[type]);
    const someOptionSelected = getIsSomeOptionSelected(
      searchParams,
      allSectionsMustHaveSelection
    );
    const selectedValuesChanged = !sameValue(resetSearchParams, searchParams);

    // old method to get title from selected values
    // const title = titleFromSelected({ searchParams: {}, title });
    const title = title_;
    const sections = organizeOptions(filterValues)(searchParams);
    const onOptionsFilter = updateFilterValue({ type, setFilterValues });
    // onDropdownClose must run without event so it defaults to empty string (see updateFilterValue)
    const onDropdownClose = updateFilterValue({ type, setFilterValues });
    const isFilterApplied = someOptionSelected && selectedValuesChanged;
    const noOfOptionsSelected =
      getSelectedOptions(searchParams).length -
      getSelectedBulkOptions(searchParams).length;

    const filter = setSearchParam({
      searchParams,
      dispatch,
      filter: type,
    });

    return {
      key: type,
      name: type,
      title,
      onOptionsFilter,
      onDropdownClose,
      isFilterApplied,
      someOptionSelected,
      noOfOptionsSelected,
      sections,
      filter,
      isError,
      userFilteredOptions: !!filterValues?.length,
    };
  };

const titleFromSelected = ({ searchParams, title }) => {
  const text = [];
  const bulkSelectedOptions = getSelectedBulkOptions(searchParams);
  const selectedOptions = getSelectedOptions(searchParams);

  if (bulkSelectedOptions?.length) {
    bulkSelectedOptions.forEach(option =>
      text.push(option.titleLabel ?? option.label)
    );
  } else {
    (selectedOptions ?? []).forEach(option =>
      text.push(option.titleLabel ?? option.label)
    );
  }

  const selectedTitles = text.join(' - ');
  return `${title}: ${selectedTitles}`;
};

const getSelectedBulkOptions = searchParams => {
  const bulkOptions =
    searchParams?.find(section => section.section === DISPATCH_CONSTANTS.ALL)
      ?.options ?? [];

  const selectedOptions = bulkOptions.filter(option => option.selected);
  return selectedOptions;
};

const getSelectedOptions = searchParams => {
  const allOptions = Object.values(searchParams ?? []).map(
    ({ options }) => options ?? []
  );
  const selectedOptions = allOptions
    .flat(Infinity)
    .filter(option => option.selected);
  return selectedOptions;
};

const updateFilterValue =
  ({ type, setFilterValues }) =>
  e => {
    const { value } = e?.target ?? { value: '' };
    setFilterValues(x => {
      return { ...x, [type]: value };
    });
  };

const getIsSomeOptionSelected = (sections, every) => {
  const method = every ? 'every' : 'some';
  return (sections ?? [])
    .filter(s => s.section !== DISPATCH_CONSTANTS.ALL)
    [method](({ options }) => (options ?? []).some(({ selected }) => selected));
};
const sameValue = (initial, current) => {
  if (!initial || !current) return false;

  let isSame = true;

  for (const section of initial) {
    if (section.section === DISPATCH_CONSTANTS.ALL) continue;
    const initialOptionList = section.options;
    const currentOptionList = current.find(
      ({ section: s }) => s === section.section
    )?.options;

    const allSameValues = initialOptionList.every(
      ({ selected }, index) => selected === currentOptionList?.[index]?.selected
    );

    if (!allSameValues) isSame = false;
  }

  return isSame;
};

const organizeOptions = userInput => sections => {
  const sortAndFilter = flow([filterInput(userInput), labelSort, selectedSort]);

  sections = (sections ?? []).map(section => ({
    ...section,
    options: avoidOrganizing(section)
      ? section.options
      : sortAndFilter(section.options),
  }));

  return sections;
};

const avoidOrganizing = section => {
  const isBulk = section.section === DISPATCH_CONSTANTS.ALL;
  const isNotMultiSelect = section.multiSelect === false;

  const avoidIf = [isBulk, isNotMultiSelect];

  return avoidIf.some(x => x);
};

const filterInput = input => data => {
  input = input?.toLowerCase();
  return (
    data?.filter(entry => {
      return (typeof entry.titleLabel === 'string' &&
        entry.titleLabel?.toLowerCase()?.includes(input)) ||
        `${entry.value}`?.toLowerCase()?.includes(input);
    }) ?? []
  );
};

const setSearchParam =
  ({ searchParams, dispatch, filter }) =>
  (value, section) =>
  () => {
    const payload = updateSelection({
      section,
      value,
      dropdownSearchParams: searchParams,
    });

    dispatch({ type: filter, payload });
  };

const updateSelection = ({
  section,
  value,
  dropdownSearchParams: searchParams,
}) => {
  const dropdownSearchParams = cloneDeep(searchParams);

  if (section === DISPATCH_CONSTANTS.ALL) {
    const { value: bulkValue, selected } = value;
    const selectAll = bulkValue === Constants.all;

    if (selectAll) toggleAll(dropdownSearchParams, !selected);
    else toggleSome(dropdownSearchParams, !selected, bulkValue);
  } else {
    const chosenSection = dropdownSearchParams.find(
      ({ section: s }) => s === section
    );
    const chosenOptions = chosenSection.options;

    const multiSelect = chosenSection.multiSelect ?? true;
    if (!multiSelect) toggleAll([chosenSection], false);

    const { value: optionValue } = value;
    let chosenValues = [];
    if (isArray(optionValue)) {
      chosenValues = chosenOptions.filter(v =>
        optionValue.some(x => x === v.value)
      );
    } else {
      chosenValues = [chosenOptions.find(v => v.value === optionValue) ?? {}];
    }

    chosenValues.forEach(
      chosenValue => (chosenValue.selected = !chosenValue.selected)
    );
  }

  findActiveBulkOptions(dropdownSearchParams);

  return dropdownSearchParams;
};

const findActiveBulkOptions = dropdownSearchParams => {
  const allOptionsIndex = dropdownSearchParams.findIndex(
    ({ section }) => section === DISPATCH_CONSTANTS.ALL
  );

  const allOptions = dropdownSearchParams[allOptionsIndex]?.options ?? [];

  allOptions.forEach(option => {
    const { value: bulkValue } = option;
    let canBeSelectedCount = 0;
    let isSelectedCount = 0;

    (dropdownSearchParams ?? []).forEach(({ section, options }) => {
      if (section === DISPATCH_CONSTANTS.ALL) return;

      canBeSelectedCount += options.filter(({ selectableBy }) =>
        selectableBy?.includes(bulkValue)
      ).length;

      isSelectedCount += options.filter(
        ({ selectableBy, selected }) =>
          selectableBy?.includes(bulkValue) && selected
      ).length;
    });

    if (isSelectedCount && canBeSelectedCount === isSelectedCount)
      option.selected = true;
    else option.selected = false;
  });
};

const toggleAll = (sections, newValue) => {
  try {
    (sections || []).forEach(section => {
      (section.options || []).forEach(option => {
        option.selected = newValue;
      });
    });
  } catch {}
};

const toggleSome = (sections, toNewValue, ifTheyHaveThisValue) => {
  (sections || []).forEach(section => {
    (section.options || []).forEach(option => {
      if (option.selectableBy?.includes(ifTheyHaveThisValue))
        option.selected = toNewValue;
    });
  });
};

const toggleMatches = (sections, matches, toNewValue) => {
  (sections || []).forEach(section => {
    (section.options || []).forEach(option => {
      if (matches.includes(option.value)) option.selected = toNewValue;
    });
  });
};

const presetParams = urlValue => {
  let toggle = false;
  if (isArray(urlValue)) {
    const hasExclude = urlValue.find(x => x === Constants.excludeFilters);
    if (hasExclude) {
      urlValue = urlValue.filter(x => x !== Constants.excludeFilters);
      toggle = true;
    }
  }

  if (typeof urlValue === 'string') {
    const hasExclude = urlValue.includes(Constants.excludeFilters);
    if (hasExclude) {
      urlValue = JSON.parse(urlValue.replace(Constants.excludeFilters, ''));
      toggle = true;
    }
  }

  return { urlValue, toggle };
};

const selectFromUrl = (searchParams, urlValue_) => {
  let newSearchParams = cloneDeep(searchParams);

  const { urlValue, toggle } = presetParams(urlValue_);
  toggleAll(searchParams, toggle);

  (searchParams ?? []).forEach(({ section }, index) => {
    const payload = updateSelection({
      section,
      value: { value: urlValue },
      dropdownSearchParams: searchParams,
    });
    newSearchParams = payload;
  });

  return newSearchParams;
};

export const hasCustomSearch = location => {
  return !!(location?.search || location?.state?.search);
};

export const hasExcludeFilter = location => {
  const search = location?.search || location?.state?.search;
  return search?.includes(Constants.excludeFilters);
};

export const specialCaseIgnore = params => {
  try {
    const { [DC.ACTOR_DATA]: ACTOR_DATA } = params;
    const specialFiltersSelected = extractSpecialFilters(ACTOR_DATA);
    const noneSelected = specialFiltersSelected.length === 0;
    return noneSelected;
  } catch (e) {
    return false;
  }
};

export const getSpecialFilters = filters => {
  try {
    const selected = extractSpecialFilters(filters);
    const specialFilters = {};
    selected.forEach(option => {
      specialFilters[option.value] = option.selected;
    });
    return specialFilters;
  } catch (e) {
    return {};
  }
};
