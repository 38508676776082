import { Field_Keys, PropKeys } from '../../constants';
import { API } from '../../lib/api';

export const getClients = async ({ token, saveClients }) => {
  const config = {
    headers: {
      Authorization: token,
    },
  };

  let {
    data: clients,
    status,
    error,
  } = await API.GET('user_profile/clients', config);

  if (status !== 200 || error) clients = [];
  saveClients(format(clients));
};

const format = data => {
  if (data == null) return [];
  return data.map(entry => formatClient(entry));
};

export const formatClient = data => {
  if (data == null) return {};
  return {
    [PropKeys.brandId]: data[Field_Keys.BRAND.ID],
    [PropKeys.brandImage]: data[Field_Keys.BRAND.IMAGE],
    [PropKeys.brandName]: data[Field_Keys.BRAND.NAME],
    [PropKeys.favoritedBrand]: data[Field_Keys.USER.IS_FAVORITE_CLIENT],
  };
};
