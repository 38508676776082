import { Text } from '../../../../../components/atoms';
import { lineChartDateformat } from '../../../../../components/organisms/LineChart/utils';
import { Colors, FontKeys } from '../../../../../components/style';
import { Constants } from '../../../../../constants';
import {
  joinStrings,
  magnitudeFormatter,
} from '../../../../../utils/stringUtils';
import {
  getBargraphKeys,
  getLinechartKeysAndLines,
  logError,
  maskEntry,
  numWithCommas,
  parseStringModifiers,
  pct,
} from '../../../../../utils/utils';
import {
  counterfeitsColors,
  depotColors,
  inactiveDangerousGoods,
  inactiveGraphs,
  inactiveShippingLabels,
  lqColors,
  parcelCountColors,
  processingTimeColors,
  requestSecondsColor,
  returnsColors,
} from '../variables';

const mask = {};

export const inactivePerformance = () => {
  const overview = [
    {
      title: 'Parcel Count',
      data: Constants.fallback,
    },
    {
      title: 'Image Count',
      data: Constants.fallback,
    },
    {
      title: 'Parcel Coverage',
      data: Constants.fallback,
    },
    {
      title: 'Processing Performance',
      data: Constants.fallback,
    },
  ];

  const detectionDetails = {
    data: [
      {
        title: 'Shipping label',
        value: Constants.fallback,
        percentage: 80,
      },
      {
        title: 'Receiver',
        value: Constants.fallback,
        percentage: 70,
      },
      {
        title: 'Host 1D Barcode',
        value: Constants.fallback,
        percentage: 60,
      },
      {
        title: 'OCR',
        value: Constants.fallback,
        percentage: 90,
      },
      {
        title: 'Sender',
        value: Constants.fallback,
        percentage: 70,
      },
      {
        title: 'Host 2D barcode',
        value: Constants.fallback,
        percentage: 80,
      },
    ],
    parcelCount: Constants.fallback,
  };

  const parcelCount = inactiveGraphs[0];
  const requestsSeconds = inactiveGraphs[1];
  const processingTime = inactiveGraphs[2];
  const counterfeits = inactiveGraphs[3];
  const returns = inactiveGraphs[4];
  const lq = inactiveGraphs[5];
  const depot = inactiveGraphs[5];

  const shippingLabels = inactiveShippingLabels;
  const dangerousGoods = inactiveDangerousGoods;
  return {
    overview,
    detectionDetails,
    parcelCount,
    requestsSeconds,
    processingTime,
    counterfeits,
    returns,
    lq,
    depot,
    shippingLabels,
    dangerousGoods,
    mock: true,
  };
};

export const formatPerformance = data => {
  if (data == null || !data.length) return inactivePerformance();
  try {
    maskEntry(data, mask);

    const { organizedData } = organizeAndFlattenData(data);

    const overview = formatOverview(organizedData);
    const detectionDetails = formatDetectionDetails(organizedData);
    const parcelCount = formatParcelCount(organizedData);
    const requestsSeconds = formatRequestsSeconds(organizedData);
    const processingTime = formatProcessingTime(organizedData);
    const counterfeits = formatCounterfeits(organizedData);
    const returns = formatReturns(organizedData);
    const lq = formatLQ(organizedData);
    const depot = formatDepot(organizedData);

    const shippingLabels = formatShippingLabels(organizedData);
    const dangerousGoods = formatDangerousGoods(organizedData);

    return {
      overview,
      detectionDetails,
      parcelCount,
      requestsSeconds,
      processingTime,
      counterfeits,
      returns,
      lq,
      depot,
      shippingLabels,
      dangerousGoods,
    };
  } catch (err) {
    logError(err);
    return inactivePerformance();
  }
};

const organizeAndFlattenData = data => {
  const organizedData = {};

  data.forEach(stat => {
    const { category, tag, date, value, label } = stat;
    const atom = { date, value };

    if (!organizedData[category]) organizedData[category] = {};

    if (!organizedData[category][tag])
      organizedData[category][tag] = { values: [], label: '' };

    organizedData[category][tag].values.push(atom);
    organizedData[category][tag].label = label;
  });

  Object.keys(organizedData).forEach(category => {
    Object.keys(organizedData[category]).forEach(tag => {
      organizedData[category][tag].total = organizedData[category][
        tag
      ].values.reduce((total, atom) => total + (atom.value ?? 0), 0);
    });
  });

  // const flatData = {};
  // Object.keys(organizedData).forEach(category => {
  //   Object.keys(organizedData[category]).forEach(tag => {
  //     flatData[tag] = organizedData[category][tag];
  //   });
  // });

  return { organizedData };
};

const runSafely = (fn, ...args) => {
  try {
    return fn(...args);
  } catch (err) {
    logError(err);
    return [];
  }
};

const formatOverview = stats => {
  const { Stats, Labels, Flags } = stats;
  const { Parcel, Image, Sorter } = Stats ?? {};
  const { Ship_Label } = Labels ?? {};
  const {
    Parcel: Flags_Parcel,
    Parcel_Image: Flags_Parcel_Image,
    Shipping_Label: Flags_Shipping_Label,
  } = Flags ?? {};

  const totalOnDaysWithNoSorterData =
    (Flags_Parcel?.total ? Flags_Parcel : Parcel)?.values?.reduce(
      (total, day, index) => {
        return total + (Sorter?.values?.[index]?.value ? 0 : day.value);
      },
      0
    ) ?? 0;

  const sorterCount = Sorter?.total;
  const parcelCount = Flags_Parcel?.total || Parcel?.total;
  const imageCount = Flags_Parcel_Image?.total || Image?.total;
  const shipCount = Flags_Shipping_Label?.total || Ship_Label?.total;

  const parcelCoverage = (
    ((parcelCount - totalOnDaysWithNoSorterData) / sorterCount) *
    100
  ).toFixed(1);
  const processingPerformance = ((shipCount / parcelCount) * 100).toFixed(1);

  const overview = [
    {
      title: 'Parcel Count',
      data: magnitudeFormatter(parcelCount),
    },
    {
      title: 'Image Count',
      data: magnitudeFormatter(imageCount),
    },
    {
      title: 'Parcel Coverage',
      data: formatPercent(parcelCoverage),
    },
    {
      title: 'Processing Performance',
      data: formatPercent(processingPerformance),
    },
  ];

  return overview;
};

const formatDetectionDetails = stats => {
  const { Labels, Stats, Flags } = stats;
  const { Ship_Label, Receiver, Code_128, Aztec, Sender } = Labels ?? {};
  const { Parcel, OCR: OCR_ } = Stats ?? {};
  const {
    Shipping_Label: Flags_Shipping_Label,
    Receiver: Flags_Receiver,
    Code1d_Code128: Flags_Code1d_Code128,
    Code2d_Aztec: Flags_Code2d_Aztec,
    Code2d_Pdf417: Flags_Code2d_Pdf417,
    Parcel: Flags_Parcel,
    Sender_OCR: Flags_Sender_OCR,
    Receiver_OCR: Flags_Receiver_OCR,
    Sender: Flags_Sender,
  } = Flags ?? {};

  const shipLabel = Flags_Shipping_Label?.total || Ship_Label?.total;
  const receiver = Flags_Receiver?.total || Receiver?.total;
  const barcode = Flags_Code1d_Code128?.total || Code_128?.total;
  const OCR =
    Math.max(Flags_Sender_OCR?.total ?? 0, Flags_Receiver_OCR?.total ?? 0) ||
    OCR_?.total;
  const sender = Flags_Sender?.total || Sender?.total;
  const aztec =
    Math.max(Flags_Code2d_Aztec?.total ?? 0, Flags_Code2d_Pdf417?.total ?? 0) ||
    Aztec?.total;
  const parcelCount = Flags_Parcel?.total || Parcel?.total;

  const shipLabelPct = Math.min(pct(shipLabel / parcelCount), 100) ?? ' - ';
  const OCRPct = Math.min(pct(OCR / parcelCount), 100);
  const senderPct = Math.min(pct(sender / parcelCount), 100);
  const receiverPct = Math.min(pct(receiver / parcelCount), 100);
  const barcodePct = Math.min(pct(barcode / parcelCount), 100);
  const aztecPct = Math.min(pct(aztec / parcelCount), 100);

  const data = [
    {
      title: 'Shipping label',
      value: numWithCommas(shipLabel),
      percentage: shipLabelPct,
    },
    {
      title: 'Receiver',
      value: numWithCommas(receiver),
      percentage: receiverPct,
    },
    {
      title: 'Host 1D Barcode',
      value: numWithCommas(barcode),
      percentage: barcodePct,
    },
    {
      title: 'OCR',
      value: numWithCommas(OCR),
      percentage: OCRPct,
    },
    {
      title: 'Sender',
      value: numWithCommas(sender),
      percentage: senderPct,
    },
    {
      title: 'Host 2D barcode',
      value: numWithCommas(aztec),
      percentage: aztecPct,
    },
  ];

  return { data, parcelCount: magnitudeFormatter(parcelCount) };
};

const formatPercent = num => {
  if (isNaN(num) || num === null) return Constants.fallback + '%';
  return num + '%';
};

const formatParcelCount = stats => {
  const { Stats, Flags } = stats;
  const { Parcel, Host, Sorter } = Stats ?? {};
  const { Parcel: Flags_Parcel } = Flags ?? {};

  let data = [];
  try {
    const dates = new Map();
    Flags_Parcel?.values?.forEach((atom, day) => {
      dates.set(atom.date, { parcel: atom.value });
    });
    Parcel?.values?.forEach((atom, day) => {
      if (!dates.has(atom.date)) {
        dates.set(atom.date, { parcel: atom.value });
      }
    });

    Sorter?.values?.forEach((atom, day) => {
      if (!dates.has(atom.date)) {
        dates.set(atom.date, { sorter: atom.value });
      } else {
        dates.set(atom.date, { ...dates.get(atom.date), sorter: atom.value });
      }
    });

    Host?.values?.forEach((atom, day) => {
      if (!dates.has(atom.date)) {
        dates.set(atom.date, { host: atom.value });
      } else {
        dates.set(atom.date, { ...dates.get(atom.date), host: atom.value });
      }
    });

    Array.from(dates)
      .sort(
        (a, b) =>
          new Date(a[0]) - new Date(b[0])
      )
      .forEach(i => {
        data.push({
          Host: i[1]?.host,
          Beumer: i[1]?.sorter,
          Countercheck: i[1]?.parcel,
          date: lineChartDateformat(i[0]),
        });
      });
  } catch {}

  const { keys, lines } = getLinechartKeysAndLines(data[0], parcelCountColors, [
    numWithCommas(Host?.total),
    numWithCommas(Sorter?.total),
    numWithCommas(Flags_Parcel?.total || Parcel?.total),
  ]);

  return { data, keys, lines };
};

const formatRequestsSeconds = stats => {
  const { Stats } = stats;
  const { Over_3, Over_4, Over_5 } = Stats ?? {};

  let data = [];
  try {
    data =
      Over_3?.values?.map((atom, day) => {
        const { date } = atom;
        return {
          '3 seconds': Over_3?.values?.[day]?.value,
          '4 seconds': Over_4?.values?.[day]?.value,
          '5 seconds': Over_5?.values?.[day]?.value,
          date: lineChartDateformat(date),
        };
      }) ?? [];
  } catch {}

  const { keys, lines } = getLinechartKeysAndLines(
    data[0],
    requestSecondsColor,
    [
      numWithCommas(Over_3?.total),
      numWithCommas(Over_4?.total),
      numWithCommas(Over_5?.total),
    ]
  );

  return { data, keys, lines };
};

const formatProcessingTime = stats => {
  const { Stats, Flags } = stats;
  const { Avg_Proc_Time, Avg_Full_Time } = Stats ?? {};
  const { SO_ALL_S } = Flags ?? {};

  let data = [];
  try {
    const dates = new Map();
    SO_ALL_S?.values?.forEach((atom, day) => {
      dates.set(atom.date, { proc: atom.value });
    });
    Avg_Proc_Time?.values?.forEach((atom, day) => {
      if (!dates.has(atom.date)) {
        dates.set(atom.date, { proc: atom.value });
      }
    });

    Avg_Full_Time?.values?.forEach((atom, day) => {
      if (!dates.has(atom.date)) {
        dates.set(atom.date, { full: atom.value });
      } else {
        dates.set(atom.date, { ...dates.get(atom.date), full: atom.value });
      }
    });

    Array.from(dates)
      .sort(
        (a, b) =>
          new Date(a[0]) - new Date(b[0])
      )
      .forEach(i => {
        data.push({
          'Processing time': i[1]?.proc,
          'OCR Processing time': i[1]?.full,
          date: lineChartDateformat(i[0]),
        });
      });
  } catch {}

  const subs = [
    (
      (SO_ALL_S?.total || Avg_Proc_Time?.total) /
      (SO_ALL_S?.total ? SO_ALL_S : Avg_Proc_Time)?.values?.filter(v => v.value)
        .length
    ).toFixed(2),
    (
      Avg_Full_Time?.total / Avg_Full_Time?.values?.filter(v => v.value).length
    ).toFixed(2),
  ];

  const { keys, lines } = getLinechartKeysAndLines(
    data[0],
    processingTimeColors,
    subs
  );

  return { data, keys, lines };
};

const formatCounterfeits = stats => {
  const { Stats, Flags } = stats;
  const { CC_50, CC_75 } = Stats ?? {};
  const { Counterfeit } = Flags ?? {};

  let data = [];

  try {
    const dates = new Map();
    Counterfeit?.values?.forEach((atom, day) => {
      dates.set(atom.date, atom.value);
    });
    CC_75?.values?.forEach((atom, day) => {
      if (!dates.has(atom.date)) {
        dates.set(atom.date, atom.value);
      }
    });

    Array.from(dates)
      .sort(
        (a, b) =>
          new Date(a[0]) - new Date(b[0])
      )
      .forEach(i => {
        data.push({
          // '50% probability': CC_50?.values?.[day]?.value,
          '75% probability': i[1],
          date: lineChartDateformat(i[0]),
        });
      });
  } catch {}
  const subs = [numWithCommas(Counterfeit?.total || CC_75?.total)];

  const { keys, lines } = getLinechartKeysAndLines(
    data[0],
    counterfeitsColors,
    subs
  );

  return { data, keys, lines };
};

const formatReturns = stats => {
  const { Labels, Flags } = stats;
  const { Return, Return_Pre_Alert } = Labels ?? {};
  const { Return: Flags_Return } = Flags ?? {};

  let data = [];
  try {
    const dates = new Map();
    Flags_Return?.values?.forEach((atom, day) => {
      dates.set(atom.date, atom.value);
    });
    Return?.values?.forEach((atom, day) => {
      if (!dates.has(atom.date)) {
        dates.set(atom.date, atom.value);
      }
    });

    Array.from(dates)
      .sort(
        (a, b) =>
          new Date(a[0]) - new Date(b[0])
      )
      .forEach(i => {
        data.push({
          Return: i[1],
          // 'Pre-alert': Return_Pre_Alert?.values?.[day]?.value,
          date: lineChartDateformat(i[0]),
        });
      });
  } catch {}
  const subs = [
    numWithCommas(Flags_Return?.total || Return?.total),
    // numWithCommas(Return_Pre_Alert?.total),
  ];

  const { keys, lines } = getLinechartKeysAndLines(
    data[0],
    returnsColors,
    subs
  );

  return { data, keys, lines };
};

const formatLQ = stats => {
  const { Labels, Flags } = stats;
  const { LQ } = Labels ?? {};
  const { LQ: Flags_LQ } = Flags ?? {};

  let data = [];
  try {
    const dates = new Map();
    Flags_LQ?.values?.forEach((atom, day) => {
      dates.set(atom.date, atom.value);
    });
    LQ?.values?.forEach((atom, day) => {
      if (!dates.has(atom.date)) {
        dates.set(atom.date, atom.value);
      }
    });

    Array.from(dates)
      .sort(
        (a, b) =>
          new Date(a[0]) - new Date(b[0])
      )
      .forEach(i => {
        data.push({
          LQ: i[1],
          date: lineChartDateformat(i[0]),
        });
      });
  } catch {}

  const subs = [numWithCommas(Flags_LQ?.total || LQ?.total)];

  const { keys, lines } = getLinechartKeysAndLines(data[0], lqColors, subs);

  return { data, keys, lines };
};

const formatDepot = stats => {
  const { Labels, Flags } = stats;
  const { Depot } = Labels ?? {};
  const { Depot0522 } = Flags ?? {};

  let data = [];
  try {
    const dates = new Map();
    Depot0522?.values?.forEach((atom, day) => {
      dates.set(atom.date, atom.value);
    });
    Depot?.values?.forEach((atom, day) => {
      if (!dates.has(atom.date)) {
        dates.set(atom.date, atom.value);
      }
    });

    Array.from(dates)
      .sort(
        (a, b) =>
          new Date(a[0]) - new Date(b[0])
      )
      .forEach(i => {
        data.push({
          Depot: i[1],
          date: lineChartDateformat(i[0]),
        });
      });
  } catch {}

  const subs = [numWithCommas(Depot0522?.total || Depot?.total)];

  const { keys, lines } = getLinechartKeysAndLines(data[0], depotColors, subs);

  return { data, keys, lines };
};

const formatShippingLabels = stats => {
  const { Ship_Label } = stats?.Labels ?? {};
  const { Ship_Labels } = stats;

  const { Shipping_Label: Flags_Shipping_Label } = stats.Flags ?? {};
  const Flags_Shipping_Labels = {};
  Object.keys(stats.Flags ?? {}).forEach(
    key =>
      key.endsWith('_Shipping_Label') &&
      (Flags_Shipping_Labels[key.replace('_Shipping_Label', '')] =
        stats.Flags[key])
  );

  const customLabel = ({ atom, sorter, percent }) => {
    const { total, label } = atom;
    const text = parseStringModifiers(`${joinStrings(sorter, label, ' - ', {
      boldFirst: true,
    })}

      ${magnitudeFormatter(total)}
      
      ${percent}%
    `);

    return (
      <Text
        text={text}
        variant={FontKeys.Variant.Caption}
        size={FontKeys.Size._M}
        weight={FontKeys.Weight.Regular}
      />
    );
  };

  const shipCount = Flags_Shipping_Label?.total || Ship_Label?.total;

  let data = [];
  try {
    data = Object.entries(
      Object.keys(Flags_Shipping_Labels).length > 0
        ? Flags_Shipping_Labels
        : Ship_Labels ?? {}
    )
      .map(([sorter, atom]) => {
        const { total } = atom;
        const percent = pct(total / shipCount ?? 0, 2);

        return {
          name: sorter,
          // total: numWithCommas(total),
          customLabel: customLabel({ atom, sorter, percent }),
          total,
        };
      })
      .filter(x => x.name !== 'False');

    data.sort((a, b) => (a.total < b.total ? 1 : -1));
  } catch (err) {}

  const { keys, bars } = getBargraphKeys(
    data[0],
    [Colors.Primary._600],
    ['percentage']
  );

  const host = data.splice(0, 1)[0];

  return {
    data,
    bars,
    totalLabels: magnitudeFormatter(shipCount),
    hostLabels: magnitudeFormatter(host?.total),
    hostName: host?.name,
  };
};

const formatDangerousGoods = stats => {
  const { Labels, Flags } = stats;
  const {
    Class_1_8: Class_1_8_,
    Class_9: Class_9_,
    UN_1: UN_1_,
    UN_3: UN_3_,
    EHS: EHS_,
  } = Labels ?? {};
  const {
    ADR: Flags_Class_1_8,
    Class9: Flags_Class_9_,
    UN1: Flags_UN_1_,
    UN3: Flags_UN_3_,
    EHS: Flags_EHS_,
  } = Flags ?? {};

  const Class_1_8 = Flags_Class_1_8?.total || Class_1_8_?.total || 0;
  const Class_9 = Flags_Class_9_?.total || Class_9_?.total || 0;
  const UN_1 = Flags_UN_1_?.total || UN_1_?.total || 0;
  const UN_3 = Flags_UN_3_?.total || UN_3_?.total || 0;
  const EHS = Flags_EHS_?.total || EHS_?.total || 0;

  const data = [
    {
      key: 'ADR - Class 1:8',
      value: parseInt(Class_1_8),
      valueLabel: magnitudeFormatter(Class_1_8),
      tooltipContent: 'Class 1-8 Hazard Tooltip Text',
      tooltipStyles: {
        tooltipWidth: '333px',
        xShift: 5.5,
        yShift: 355,
      },
    },
    {
      key: 'ADR - Class 9',
      value: parseInt(Class_9),
      valueLabel: magnitudeFormatter(Class_9),
      tooltipContent: 'Class 9 Hazard Tooltip Text',
      tooltipStyles: {
        tooltipWidth: '253px',
        xShift: 5.5,
        yShift: 115,
      },
    },
    {
      key: 'UN 1XXX',
      value: parseInt(UN_1),
      valueLabel: magnitudeFormatter(UN_1),
      tooltipContent: 'UN 1XXX Tooltip Text',
      tooltipStyles: {
        xShift: 5.5,
        yShift: 115,
      },
    },
    {
      key: 'UN 3XXX',
      value: parseInt(UN_3),
      valueLabel: magnitudeFormatter(UN_3),
      tooltipContent: 'Lithium Batteries',
      tooltipStyles: {
        xShift: 5.5,
        yShift: 57,
      },
    },
    {
      key: 'EHS',
      value: parseInt(EHS),
      valueLabel: magnitudeFormatter(EHS),
      tooltipContent: 'Environmentally Hazardous Substance',
      tooltipStyles: {
        xShift: 5.5,
        yShift: 74,
      },
    },
  ];

  const total = data.reduce((acc, curr) => acc + parseInt(curr.value ?? 0), 0);

  const main = {
    value: magnitudeFormatter(total),
    label: 'Total Labels',
  };

  return { data, total, main };
};
